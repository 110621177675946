:root
{
	--ov_only_on_model_display: inherit;
}

@font-face
{
	font-family: Quicksand;
	src: url('Quicksand/Quicksand-Regular.ttf');
}

html, body
{
	color: var(--ov_foreground_color);
	background: var(--ov_background_color);
	font-size: 16px;
	font-family: Quicksand, Helvetica, sans-serif;
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	overflow: hidden;
}

a
{
	color: var(--ov_button_color);
	text-decoration: none;
}

img
{
	display: block;
}

ol, ul
{
	padding: 0px 25px;
	margin: 0px;
}

li
{
	margin: 10px 0px;
	line-height: 25px;
}

.only_on_model
{
	display: var(--ov_only_on_model_display);
}

input, select, textarea
{
	font-family: Quicksand, Helvetica, sans-serif;
	font-size: 16px;
	outline: none;
}

@media (hover)
{

a:hover
{
	text-decoration: underline;
}

}

@media (max-width: 800px)
{

.only_full_width
{
	display: none;
}

}
